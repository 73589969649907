/*

This is the likes page where the user will be able to see all of their likes
This page utilizes a library called Swiper which gives it the effect of scrolling horizontally.


Endpoint variables:
    - likedUser --> This endpoint stores the data of all the liked users.


*/

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// import Swiper core and required modules
import SwiperCore, {
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/core";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Navbar from "./navigationBar_homepage_likespage";
import noveilAxios from "../configs/axiosConfig";
import { Redirect } from "react-router-dom";
import { clearAllIntervals } from "../utils";
import avatar from "../assets/img/avatar.jpg"
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'
// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination, Navigation]);

// Constructor for Likes page
const Likes = () => {
  let history = useHistory();

  /* To get data from server, go inside terminal and type "cd server". Type in npm install and press enter, to download all dependencies. After that type "node index.js" and press enter to run the server. */

  // The state of users gathered from the database
  const [likedUser, setLikedUser] = useState([]);
  const [userId, setUserId] = useState(null);

  const handleMessageClick = (e, id) => {
    e.preventDefault();
    setUserId(id);
  };

  // The dynamic function that gets each user's information and sets that data in the above state, as a list, for rendering.

  const initSlide = Math.floor(likedUser.length / 2);
  // console.log("initSlide", initSlide);
  useEffect(() => {
    // This function is basically getting the data from the backend and sets the state with the information received.
    clearAllIntervals();
    let logintoken = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + logintoken,
      },
    };
    noveilAxios
      .get("like/", config)
      .then((response) => {
        setLikedUser(response.data.my_likes);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formatDate = (date, format) => {
    return moment(date).format(format);
  };

  console.log("USER ID", userId);
  if (userId) {
    return (
      <Redirect
        to={{
          pathname: "/chatpage",
          state: { messageUserId: userId },
        }}
      />
    );
  }

  return (
    //This is the background styling
    <div className={"bg"}>
      {/*Navbar components for logo and nav button features */}
      <Navbar />

      {/*This is the "Your Likes" header div*/}
      <div className={"likes-hl"}>
        <h1>Your Likes</h1>
      </div>

      {/*This is the likes container*/}
      <div className={"likes-container"}>
        <>
          {/*This is the Swiper effect and styling for the likes carousel navigation*/}
          {likedUser.length !== 0 ? (
            <Swiper
              // autoHeight
              initialSlide={initSlide}
              effect={"coverflow"}
              grabCursor={false}
              centeredSlides={true}
              slidesPerView={"auto"}
              coverflowEffect={{
                rotate: 20,
                stretch: 0,
                depth: 200,
                modifier: 1,
                slideShadows: false,
              }}
              allowTouchMove={true}
              className="mySwiper"
              slidePrev={true}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
            >
              {/*This is the slide div that displays the user that has liked someone*/}
              {likedUser.map((val, key) => (
                <SwiperSlide virtualIndex={key} className="slide-flip">
                  {console.log(val)}
                  {/*This div holds both front and back card for flip effect*/}
                  <div className={"flip-card-inner"}>
                    {/*This div holds the front side of the card with image and time liked*/}
                    <div className={"front-card"}>
                      <img
                        className={"front-img"}
                        alt=""
                        src={
                          val.profile.avatar && val.profile.avatar.length
                            ? val.profile.avatar
                            : avatar
                        }
                      />
                      <div className={"liked-time"}>
                        <h1>
                          {formatDate(val.profile.created_at, "MMMM Do YYYY")}
                        </h1>
                      </div>
                    </div>

                    {/*This div holds the back of the card with a circular image, name, school and send message button*/}
                    <div className={"back-card"}>
                      <img src={val.profile.avatar} alt="" />
                      <div className={"back-card-text"}>
                        <h1 className="text-bold">{val.first_name}</h1>
                        <h1>@{val.username}</h1>
                        {/* <h2>{val.university}</h2> */}
                        <button
                          onClick={(e) => handleMessageClick(e, val.user_id)}
                          style={{zIndex: 1000}}
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ):
          <div className="text-bold sub-title">No likes yet</div>
          }
        </>

        {/*These are the divs for the left and right carousel navigation arrows*/}
        <div className="mg-left swiper-button-prev swiper-button-white" />
        <div className="mg-right swiper-button-next swiper-button-white" />
      </div>
    </div>
  );
};

export default Likes;
