/*

This is the timer component for the date page.
This component contains styling and logic that makes the timer countdown with a circular animation.
There are no endpoints needed for this component.

*/

import React, { useEffect, useState } from "react";
import Modal from "./finishedDatePopupModal";
import noveilAxios from "../configs/axiosConfig";

// Constructor for timer component
const Timer = ({
  callback,
  matchName,
  userId,
  timeLimit = 60,
  warningTime = 40,
  alertThreshold = 10,
  rtc,
  stopTimer = false,
}) => {
  // const {callback, timeLimit, warningTime, alertThreshold}
  // State function that opens the poll modal upon time completion
  const [isOpen, setIsOpen] = useState(false);

  // This state controls the visual number labels put inside the timer
  const [timerLabel, setTimerLabel] = useState("");

  const [userid, setUserid] = useState(userId);

  //  Alert warnings based on time left
  const FULL_DASH_ARRAY = 283;
  // Colors that distinguish the different time alerts
  const COLOR_CODES = {
    info: {
      color: "green",
    },
    warning: {
      color: "orange",
      threshold: warningTime,
    },
    alert: {
      color: "red",
      threshold: alertThreshold,
    },
  };

  // Set timer
  let timePassed = 0;
  let timeLeft = timeLimit;
  let timerInterval = null;
  let remainingPathColor = COLOR_CODES.info.color;

  // This function clears the timer interval when time finishes
  const onTimesUp = () => {
    let loginToken = localStorage.getItem("token");
    callback();
    try {
      rtc.localStream.close();
    } catch (e) {
      console.error("COULD_NOT_STOP_RTC_CLIENT", e);
    }
    noveilAxios
      .post("video-call/end/", null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + loginToken,
        },
      })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
    clearInterval(timerInterval);
  };

  // Function to show modal
  function showModal() {
    setIsOpen(true);
  }

  if (stopTimer) {
    onTimesUp();
  }

  // This function keeps the timer function from restarting after finishing
  useEffect(() => {
    // Start timer function which handles countdown and poll modal state upon completion
    timerInterval = setInterval(() => {
      timePassed = timePassed += 1;
      timeLeft = timeLimit - timePassed;
      // document.getElementById("base-timer-label").innerHTML = formatTime(
      //     timeLeft
      // );

      const x = formatTime(timeLeft);

      setTimerLabel(x);

      setCircleDasharray();
      setRemainingPathColor(timeLeft);

      if (timeLeft === 0) {
        onTimesUp();

        if (formatTime(timeLeft) === "0:00") {
          showModal();
        }
      }
    }, 1000);

    // move clean up function to here:
    return () => {
      clearInterval(timerInterval.current);
      // timerInterval.current = null;
    };
  }, [timeLimit]);

  //Format the time count down to x:xx
  function formatTime(time) {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;

    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  }

  // Set path color based on alert codes
  function setRemainingPathColor(timeLeft) {
    const { alert, warning, info } = COLOR_CODES;
    if (timeLeft <= alert.threshold) {
      document
        .getElementById("base-timer-path-remaining")
        .classList.remove(warning.color);
      document
        .getElementById("base-timer-path-remaining")
        .classList.add(alert.color);
    } else if (timeLeft <= warning.threshold) {
      document
        .getElementById("base-timer-path-remaining")
        .classList.remove(info.color);
      document
        .getElementById("base-timer-path-remaining")
        .classList.add(warning.color);
    }
  }

  // Calculate the fraction of time left
  function calculateTimeFraction() {
    const rawTimeFraction = timeLeft / timeLimit;
    return rawTimeFraction - (1 / timeLimit) * (1 - rawTimeFraction);
  }

  // Function that handles circle operation
  function setCircleDasharray() {
    const circleDasharray = `${(
      calculateTimeFraction() * FULL_DASH_ARRAY
    ).toFixed(0)} 283`;
    document
      .getElementById("base-timer-path-remaining")
      .setAttribute("stroke-dasharray", circleDasharray);
  }

  return (
    // This is the div for the timer
    <div className="base-timer">
      {/*This is the timer label which shows the actual countdown through numbers*/}
      <div className="base-timer__label">
        <p id="base-timer-label">{timerLabel}</p>
      </div>
      {/*This is the base timer svg that sets the framework for the timer body*/}
      <svg
        className="base-timer__svg"
        viewBox="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/*This is the circle for which the countdown timer is displayed*/}
        <g className="base-timer__circle">
          <circle className="base-timer__path-elapsed" cx="50" cy="50" r="45" />
          <path
            id="base-timer-path-remaining"
            strokeDasharray="283"
            className="base-timer__path-remaining ${remainingPathColor}"
            d="
              M 50, 50
              m -45, 0
              a 45,45 0 1,0 90,0
              a 45,45 0 1,0 -90,0
            "
          />
        </g>
      </svg>
      {/* This is the Modal state needed for timer to work */}
      {isOpen && <Modal userid={userid} matchName={matchName} />}
    </div>
  );
};

export default Timer;
