import { useState } from "react";
import { useHistory } from "react-router-dom";
import noveilAxios from "../configs/axiosConfig";

function ResetPassword() {
  const history = useHistory();

  const [email, setEmail] = useState("");

  const [errorMessage, setErrorMessage] = useState(null);

  const addEmail = () => {
    // let logintoken = localStorage.getItem("token");
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Token " + logintoken,
    //   },
    // };
    delete noveilAxios.defaults.headers["Authorization"];
    noveilAxios
      .post("reset-password/", {
        email,
      })
      .then(() => {
        console.log("success");
      })
      .catch((error) => {
        console.log(error?.response);
        if (error?.response?.data) {
          let errMsg = "";
          for (let key in error.response.data) {
            errMsg += error.response.data[key] + "\n";
          }
          setErrorMessage(errMsg);
        } else {
          setErrorMessage(error.message);
        }
      });
  };

  return (
    <div className={"bg"}>
      <div style={{ height: "250px" }} className={"form-box"}>
        <h4 style={{ "text-align": "center" }}> Forgot Password </h4>
        <input
          style={{ "margin-top": "50px", padding: "10px"}}
          type={"email"}
          onChange={(event) => {
            setEmail(event.target.value);
          }}
          className={"input-field"}
          placeholder={"Email"}
          required
        />
        <button
          style={{ "margin-top": "15px" }}
          onClick={addEmail}
          className="submit-btn"
        >
          Submit
        </button>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          {errorMessage && (
            <p
              style={{
                color: "orange",
                fontSize: 14,
                // textShadow: "#fff 0px 0px 1px",
                fontWeight: "bold",
              }}
            >
              {errorMessage}
            </p>
          )}
        </div>

        <button
          className="submit-btn"
          style={{ marginTop: 20, background: "bisque" }}
          onClick={() => history.push("/login")}
        >
          <i className="fas fa-arrow-alt-circle-left" style={{ fontSize: 15 }}>
            {" "}
          </i>{" "}
          Go to Login
        </button>
      </div>
    </div>
  );
}

export default ResetPassword;
