import axios from "axios";

let API_URL;
if (process.env.NODE_ENV === "production") {
  API_URL = process.env.REACT_APP_API_URL;
} else {
  API_URL = "https://test.no-veil.com/api/";
}

let baseURL = `${API_URL}`;

const noveilAxios = axios.create({
  baseURL: baseURL,
});

const updateAuth = () => {
  console.log("auth")
  if (localStorage.getItem("token")) {
    noveilAxios.defaults.headers[
      "Authorization"
    ] = `Token ${localStorage.getItem("token")}`;
  }
};

export { updateAuth };
export default noveilAxios;
