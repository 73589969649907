import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { LoginContext } from "../helper/context";

export default function ProtectedRoute(props) {
    const Component = props.component;
    const token = localStorage.getItem('token')
    const {isAuthenticated}= useContext(LoginContext)

    const auth = isAuthenticated || token
   
    return auth ? (
        <Component/>
    ) : (
        <Redirect to={{ pathname: '/login'}} />
    );
}
