import { useState } from "react";
import { useHistory } from "react-router-dom";
import noveilAxios from "../configs/axiosConfig";

function VerifyCode() {
  let history = useHistory();

  const [code, setCode] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const addCode = () => {
    let logintoken = localStorage.getItem("token");

    const formData = new FormData();

    formData.append("code", code);

    noveilAxios
      .post("verify-email/", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + logintoken,
        },
      })
      .then(() => {
        console.log("success");
        history.push("/personal");
      })
      .catch((error) => {
        if (error.response) {
          let errMsg = "";
          for (let key in error.response.data) {
            errMsg += error.response.data[key] + "\n";
          }
          setErrorMessage(errMsg);
        } else {
          setErrorMessage(error.message);
        }
      });
  };

  const resendCode = () => {
    let logintoken = localStorage.getItem("token");

    noveilAxios
      .post("resend-email-verification/", null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + logintoken,
        },
      })
      .then(() => {
        console.log("success");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={"bg"}>
      <div style={{ height: "250px" }} className={"form-box flex flex-col"}>
        <h4 style={{ "text-align": "center" }}> Enter Verification Code </h4>
        <input
          style={{ marginTop: "50px", padding: "10px" }}
          type={"text"}
          onChange={(event) => {
            setCode(event.target.value);
          }}
          className={"input-field"}
          placeholder={"Code"}
          required
        />
        <button
          style={{ "margin-top": "15px" }}
          onClick={addCode}
          className="submit-btn"
        >
          Submit
        </button>
        <div style={{ "margin-top": "10px" }}>
          <p
            style={{ "text-align": "center", cursor: "pointer" }}
            onClick={resendCode}
          >
            {" "}
            Re-send Verification Code{" "}
          </p>
          {errorMessage ? (
            <p style={{ textAlign: "center", color: "orange" }}>
              {errorMessage}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default VerifyCode;
