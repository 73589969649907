/*

 This is the Chat page. The user will be able to message with other users they have matched with.
 The user will be able to see previous conversations on the right side of chat container. Users
 will also be able to go straight from this page back ito the dating pool, with the "Get back dating
 button"

 Endpoints:

 -users --> Needs to be called to retrieve username and Profile pics of the user's matches
 -conversations --> Needs to call previous conversations with user's other matches
 -currentChat --> sets the user that the user is currently chatting with: includes
                  the other users Profile pic and name, & their previous messages
 -messages --> calls messages after they are sent to backend
 -newMessages --> calls messages that the user sends to other users
 -arrivalMessages --> calls messages that the user receives from other users

*/

import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Message from "./chatPageComponents/messageFormat";
// import send from "./send.png";
import Icons from "./homePageIcons";
import Navbar from "./navigationBar_homepage_likespage";
import noveilAxios from "../configs/axiosConfig";
import Loader from "react-loader-spinner";
import { clearAllIntervals } from "../utils";
import avatar from "../assets/img/avatar.jpg";

export default function Chat() {
  const [status, setStatus] = useState({});
  const history = useHistory();
  const [conversations, setConversations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const scrollRef = useRef();
  const [userid, setUserid] = useState(
    history.location &&
      history.location.state &&
      history.location.state.messageUserId
      ? history.location.state.messageUserId
      : null
  );
  const [pollingId, setPollingId] = useState(null);
  const [totalFriends, setTotalFriends] = useState(0);
  const [canSend, setCanSend] = useState(false);
  const [messageRemaining, setMessageRemaining] = useState(0);
  const [dateName, setDateName] = useState("");
  const [loading, setLoading] = useState(false);
  const [locationKeys, setLocationKeys] = useState([]);

  /**
   * Hook that alerts clicks outside of the passed ref
   */
  // console.log(userid)
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          history.push("/home");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const clearAllIntervals = () => {
    const interval_id = window.setInterval(function () { },
      Number.MAX_SAFE_INTEGER);
    for (let i = 1; i < interval_id; ++i) {
      clearInterval(i);
    }
  };

  const getUsers = async () => {
    try {
      const res = await noveilAxios.get("personal/", config);
      if (res?.data?.length) {
        // Sorting conversations based the remaining messages
        let { data } = res;
        data = data.sort((firstEl, secondEl) => {
          if (firstEl.can_send_message && !secondEl.can_send_message) return -1;
          if (!firstEl.can_send_message && secondEl.can_send_message) return 1;
          return 0;
        });
        if (history?.location?.state?.messageUserId) {
          const selectedUser = res.data.filter(
            (user) => user.user_id === history.location.state.messageUserId
          );
          if (selectedUser.length) {
            const _user = selectedUser[0];
            setUserid(_user.user_id);
            setLoading(true);
            setDateName(_user.username);
            setConversations(data);
            return;
          }
        }

        setUserid(data[0].user_id);
        setLoading(true);
        setDateName(data[0].username);
        setConversations(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
          clearAllIntervals();
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
          clearAllIntervals();
        }
      }
    });
  }, [locationKeys]);

  useEffect(() => {
    noveilAxios
      .get("home/status")
      .then((res) => {
        let { online_users, dates_remaining, reset_time, dated } = res.data;
        reset_time = Number(reset_time);
        setStatus({ online_users, dates_remaining, reset_time, dated });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setInterval(() => {
      noveilAxios
        .get("home/status")
        .then((res) => {
          let { data } = res;
          if (!data.profile_complete) {
            clearAllIntervals();
            history.push("/personal");
            return;
          }
          if (!data.gender_complete) {
            clearAllIntervals();
            history.push("/gquestion");
            return;
          }
          if (!data.interest_complete) {
            clearAllIntervals();
            history.push("/dquestion");
            return;
          }
          setStatus((status) => ({
            ...status,
            online_users: data.online_users,
            dated: data.dated,
            dates_remaining: data.dates_remaining,
          }));
        })
        .catch((err) => console.log(err));
    }, 3000);

    setInterval(() => {
      setStatus((status) => ({ ...status, reset_time: status.reset_time - 1 }));
    }, 1000);
  }, []);

  const username = localStorage.getItem("username");
  let loginToken = localStorage.getItem("token");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + loginToken,
    },
  };

  useEffect(() => {
    getUsers();
  }, [totalFriends]);

  const getConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + loginToken,
    },
    params:
      userid !== null
        ? {
          user_id: userid,
        }
        : {},
  };

  useEffect(() => {
    if (typeof pollingId === "number") {
      clearInterval(pollingId);
    }
    const getMessages = () => {
      clearAllIntervals();
      const id = setInterval(() => {
        clearAllIntervals();
        noveilAxios
          .get(`upload/message/`, getConfig)
          .then((response) => {
            if (totalFriends !== response.data.total_friends) {
              setTotalFriends(response.data.total_friends);
            }
            setCanSend(response.data.can_send);
            setMessageRemaining(response.data.messages_remaining);
            setMessages(response.data.messages);
            setStatus((status) => ({
              ...status,
              dated: response.data.dated,
              reset_time: response.data.reset_time,
            }));
            setLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }, [1000]);
      setPollingId(id);
    };
    getMessages();
  }, [messages, userid]);

  //Logic that allows button to send message into the Current Chat
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newMessage.length === 0) return;
    if (newMessage.match(/^\s*$/gi)) return;
    let loginToken = localStorage.getItem("token");

    // const receiverId = currentChat.members.find(
    //     (member) => member !== user._id
    // );

    const formData = new FormData();

    formData.append("message", newMessage);

    noveilAxios
      .post("upload/message/", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + loginToken,
        },
        params: {
          user_id: userid,
        },
      })
      .then((res) => {
        setMessages([...messages, res.data]);
        setNewMessage("");
      })
      .catch((error) => console.log(error));
  };

  // This logic should autoscroll chat box when new message is sent
  // useEffect(() => {
  //     scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  // }, [messages]);

  //chatPageComponents page constructor

  let msgTitle = "";
  if (conversations.length === 0 && loading)
    msgTitle =
      "You need to videochat and like each other to message each other";
  else msgTitle = "Messages";
  return (
    //This is the background styling
    <div className={"bg"}>
      {/* //Navbar components for logo and nav button features */}
      <Navbar />

      {/* //Chat Component */}

      <div
        ref={wrapperRef}
        className="chat-container"
        style={{ "overflow-x": "hidden", "overflow-y": "auto" }}
      >
        {/* // Contains "Get Back Dating" button and "messages logo" */}
        <div id={"message-title"}>
          <div id={"chat-date-button"}>
            <Link
              to={"/datepage"}
              className={"submit-link"}
              style={{ textDecoration: "none" }}
            >
              <button className={"chat-start"}>Get Back Dating!</button>
            </Link>
          </div>
          <h1 id={"messages-word"}>Noveil Chat</h1>

          {
            conversations.length ?
              conversations.map(
                (c, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      setLoading(true);
                      setDateName(c.username);
                      setUserid(c.user_id);
                      setNewMessage("");
                    }}
                  >
                    <div
                      className={
                        userid === c.user_id ? "conversation active" : "conversation"
                      }
                    >
                      <img
                        className="conversationImg"
                        src={c.avatar ? c.avatar : avatar}
                        alt=""
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <h2 style={{ height: "100%" }} className="title-text">
                          @{c.username}
                        </h2>
                        {!c.can_send_message && (
                          <i
                            class="fa fa-lock mg-left"
                            style={{ color: "yellow", fontSize: 12 }}
                            aria-hidden="true"
                          ></i>
                        )}
                      </div>
                      {/* <div className="conversation-message">{messages.text}</div>
                                <div className="conversation-time">{format(messages.createdAt)}</div> */}
                    </div>
                  </div>
                )
              )
              : <div className="normal padded"></div>}
        </div>
        {/* // Styling for current open chat: includes User chatting, chat box,"Send message" form */}

        <div
          className="total-chat"
          style={{
            "overflow-x": "hidden",
            "overflow-y": "auto",
            position: "relative",
          }}
        >
          {/* // ENDPOINTS: user.username, user.profilePic;  Current chat profile pic and username */}
          <div id="chat-title">
            {/*<img*/}
            {/*  className="profile-pic"*/}
            {/*  src={*/}
            {/*    user?.profilePic*/}
            {/*      ? user.profilePic*/}
            {/*      : "http://3.130.45.207/media/users/avatar/2021/09/21/user.jpg"*/}
            {/*  }*/}
            {/*  height="150"*/}
            {/*  alt="profile"*/}
            {/*/>*/}
            <p>
              {loading ? (
                <Loader type="Hearts" color="#ff105f" height={20} width={50} />
              ) : dateName ? (
                messageRemaining + " messages remaining "
              ) : (
                msgTitle
              )}
            </p>
          </div>
          {/* // ENDPOINTS: user._id;  Chat box that shows messages as they are sent */}
          <div id="chat-box">
            {/* {console.log("messages", messages)} */}
            {totalFriends && messages && dateName && userid !== null && !loading
              ? messages.map((m, index) => (
                <div key={index} ref={scrollRef}>
                  <Message
                    message={m.message}
                    sender={m.sender}
                    createdAt={m.created_at}
                  />
                </div>
              ))
              : <div className='flex flex-col'>
                <div>
                  No messages yet!
                </div>
                <div> Please select a conversation.</div>
              </div>}
          </div>
          {/* // ENDPOINTS: newMessage;   Form where messages are typed and sent */}
          {loading ? null : totalFriends && messages && canSend && dateName ? (
            <div id="chat-form">
              <input
                style={{ height: 40, marginLeft: 10 }}
                className="textbox"
                type="text"
                placeholder="Send a message..."
                onKeyPress={(e) => {
                  if (e.charCode === 13) handleSubmit(e);
                }}
                onChange={(e) => setNewMessage(e.target.value)}
                value={newMessage}
              />
              <div className="send">
                <i
                  className="fas fa-arrow-alt-circle-right"
                  onClick={handleSubmit}
                  style={{ fontSize: "50px" }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {/* // Icon components for signout and dates-left feature */}
      <Icons
        dated={status.dated}
        resetTime={status.reset_time}
        datesRemaining={status.dates_remaining}
      />
    </div>
  );
}
