/*
 This is the loading page containing the loading animation. This page should
 run while the user is waiting to be set up on a date.

 */

import React from "react";
const Loading = () => {
  let hearts = [];
  for (let i = 0; i < 12; i++) {
    hearts.push(
      <span>
        <i className={"fas fa-heart"} />
      </span>
    );
  }
  return <div className="estrellas">{hearts}</div>;
};

export default Loading;
