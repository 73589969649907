import { BeatLoader } from "react-spinners";

const Spinners = (props) => {
  const { message, ...rest } = props;
  return (
    <div className="full-screen centering">
      <BeatLoader color="#ffad06" />
      {message && message.length ? <h1>{message}</h1> : null}
    </div>
  );
};

export default Spinners;
