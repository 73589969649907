/*

This react file defines the form of the messages in the chat box

Endpoints:

{message.text} -> Messages are received from backend and sent into this style

{message.createdAt} -> Retrieves the time the message was sent at. Format is taking from timeago.js
                        which can be npm installed or downloaded through github

 */

import { format } from "timeago.js";

export default function Message({ message, sender, createdAt}) {
    let username = localStorage.getItem('username')
    sender = (sender === username) ? 'me': sender
    return (
        //Format of message
        // Some logic that defines if message is from user or to user
        <div style={{marginTop:8}} className={sender === 'me' ? "message-own" : "their-message"}>

            {/* //ENDPOINTS: message text, time message is sent */}
            <div className="messageTop">
                <p className="message-text">{message}</p>
            </div>
            <div className="messageBottom">{format(createdAt)}</div>

        </div>
    );
}