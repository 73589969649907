import { useState } from "react";
import { useHistory } from "react-router-dom";
import noveilAxios from "../configs/axiosConfig";

function Personal() {
  let history = useHistory();

  const [firstname, setFirstname] = useState("");
  const [middlename, setMiddlename] = useState("");
  const [lastname, setLastname] = useState("");
  const [file, setFile] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const logintoken = localStorage.getItem("token");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + logintoken,
    },
  };

  const addinfo = (e) => {
    e.preventDefault()
    const formData = new FormData();

    formData.append("first_name", firstname);
    formData.append("middle_name", middlename);
    formData.append("last_name", lastname);
    formData.append("avatar", file);

    noveilAxios
      .post("personal/", formData, config)
      .then(() => {
        console.log("success");
        history.push("/gquestion");
      })
      .catch((error) => {
        // console.log(error.response.data)
        if (error.response) {
          let errMsg = "";
          for (let key in error?.response?.data?.errors) {
            errMsg += error?.response?.data?.errors[key] + "\n";
          }
          setErrorMessage(errMsg);
        } else {
          setErrorMessage(error.message);
        }
      });
  };

  // console.log(errorMessage)
  return (
    <div className={"bg"}>
      <div className={"form-box"}>
        <h4 style={{ "text-align": "center" }}> Personal information</h4>
        <div style={{ margin: "15px" }}>
          <form>
            <input
              style={{ padding: "10px" }}
              type={"text"}
              onChange={(event) => {
                setFirstname(event.target.value);
              }}
              className={"input-field"}
              placeholder={"First name"}
              required
            />
            {/* <input
            style={{ padding: "10px" }}
            type={"text"}
            onChange={(event) => {
              setMiddlename(event.target.value);
            }}
            className={"input-field"}
            placeholder={"Middle name"}
            required
          /> */}
            <input
              style={{ padding: "10px" }}
              type={"text"}
              onChange={(event) => {
                setLastname(event.target.value);
              }}
              className={"input-field"}
              placeholder={"Last name"}
              // required
            />
            <div className="file-upload-button-wrap">
              <label
                className="file-upload-button"
                for="upload"
                // onClick={() => {
                //   if (file.length !== 0) {
                //     setFile("");
                //   }
                // }}
              >
                Upload Profile Picture
              </label>
              <input
                id="upload"
                type="file"
                onChange={(event) => {
                  setFile(event.target.files[0]);
                }}
                accept="image/png, image/jpeg"
                required
              />
              <button
                style={{
                  float: "right",
                  height: 40,
                  marginTop: 10,
                  padding: 10,
                }}
                onClick={() => {
                  document.getElementById("upload").value = null;
                  setFile("");
                }}
              >
                X
              </button>
            </div>

            <button
              style={{ "margin-top": "20px" }}
              onClick={addinfo}
              className="submit-btn"
            >
              Submit
            </button>
          </form>
        </div>
        {errorMessage ? (
          <p style={{ textAlign: "center", color: "orange" }}>{errorMessage}</p>
        ) : null}
      </div>
    </div>
  );
}

export default Personal;
