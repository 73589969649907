/*

This is the home page where the user will have access to many different features.
On the top there will be a navigation bar which will hold the logo on the far left and the other Navigation buttons on the far right.
In the middle of the page, there is the date box that shows the amount of users online and the date button that takes the user to the date page.
On the bottom of the home page, there is the icon component which holds the 'dates left' & 'date reset timer' on the far left, and the sign out button on the far right.

Endpoint variables:
    - usersOnline --> This endpoint stores the data of the amount of users currently online.


    *The 'dates left' & 'date reset timer' endpoints will be in the 'homePageIcons.js' component file
*/

import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { LoginContext } from "../helper/context";
import Icons from "./homePageIcons";
import Spinners from "./spinners";
import Navbar from "./navigationBar_homepage_likespage";
import { Grid } from "@mui/material";
import { clearAllIntervals } from "../utils";
import noveilAxios, { updateAuth } from "../configs/axiosConfig";
import Popup from 'reactjs-popup'
// This is the home page

const Home = () => {
  const history = useHistory();
  const [status, setStatus] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [countDownFlag, setCountDownFlag] = useState(true);

  //This is the endpoint state that stores the number of users online
  // const [usersOnline, setUsersOnline] = useState('');

  const { username, setUsername } = useContext(LoginContext);
  const startDate = () => {
    history.push("/datepage");
  };

  // This endpoint will fetch data from some database that sums up the amount of users online
  // useEffect(() => {
  //     //The fetch link below is from the likes page and should be used as an example.
  //     Axios.get('http://localhost:3005/likedUser').then((response) => {
  //         setUsersOnline(response.data)
  //     }).catch(err => {
  //         console.log(err);
  //     })
  // }, )

  useEffect(() => {
    updateAuth();
    // setUsername(localStorage.getItem("username"))
    noveilAxios
      .get("home/status/")
      .then((res) => {
        if (!res.data.profile_complete) {
          clearAllIntervals();
          history.push("/personal");
          return;
        }
        if (!res.data.gender_complete) {
          clearAllIntervals();
          history.push("/gquestion");
          return;
        }
        if (!res.data.interest_complete) {
          clearAllIntervals();
          history.push("/dquestion");
          return;
        }
        let {
          dated,
          name,
          online_users,
          dates_remaining,
          reset_time,
          unread_messages,
          new_likes,
        } = res.data;
        reset_time = Number(reset_time);
        setStatus({
          dated,
          name,
          online_users,
          dates_remaining,
          reset_time,
          unread_messages,
          new_likes,
        });
        setIsLoaded(true);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    clearAllIntervals();
    setInterval(() => {
      noveilAxios
        .get("home/status/", config)
        .then((res) => {
          let { data } = res;
          if (!data.profile_complete) {
            clearAllIntervals();
            history.push("/personal");
            return;
          }
          if (!data.gender_complete) {
            clearAllIntervals();
            history.push("/gquestion");
            return;
          }
          if (!data.interest_complete) {
            clearAllIntervals();
            history.push("/dquestion");
            return;
          }
          setStatus((status) => ({
            ...status,
            online_users: data.online_users,
            new_likes: data.new_likes,
            unread_messages: data.unread_messages,
            reset_time: data.reset_time,
            dates_remaining: data.dates_remaining,
          }));
        })
        .catch((err) => console.log(err));
    }, 3000);
  }, []);

  useEffect(() => {
    if (countDownFlag && status.reset_time) {
      setCountDownFlag(false);
      setInterval(() => {
        setStatus((status) => ({
          ...status,
          reset_time: status.reset_time - 1 > 0 ? status.reset_time - 1 : 0,
        }));
      }, 1000);
    }
  }, [status.reset_time]);

  const reportUser = (e, message) => {
    e.preventDefault();
    noveilAxios
      .post("video-call/report/", { message: message })
      .then(() => {
        // setReported(true);
      })
      .catch(() => {
        console.error("Failed to submit report");
      });
  };

  if (isLoaded)
    return (
      //This is the background styling
      <div className={"bg"}>
        {/*Navbar components for logo and nav button features */}
        <Navbar />

        {/*This is the start dating card*/}
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          style={{ textAlign: "center", position: "relative" }}
        >
          <Grid
            item
            xs={12}
            md={4}
            container
            alignItems={"center"}
            justifyContent={"center"}
          >
            {/* <div className={"home-card"}>Profile button</div> */}
            <div className={"home-card"}>
              {/*Header inside date card*/}
              <Grid container style={{ textAlign: "center", padding: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1>
                  {status.name}, Ready to find a match?
                </h1>

                {/*Sub header inside date card*/}
                <div
                  className={"online"}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  {/*REPLACE BELOW DATA POINT WITH: <h2>usersOnline</h2>*/}
                  <h2>
                    {" "}
                    <i
                      style={{ fontSize: 15 }}
                      className="fas fa-circle"
                    />{" "}
                    {status.online_users} Users Online
                  </h2>
                </div>
                {/*Start dating button and link to date page*/}
                <button
                  onClick={startDate}
                  className={"home-start submit-link"}
                >
                  Start Dating!
                </button>
              </Grid>
            </div>
          </Grid>
        </Grid>

        {/*Icon components for sign out and dates-left feature*/}
        <Icons
          dated={status.dated}
          resetTime={status.reset_time}
          datesRemaining={status.dates_remaining}
        />
      </div>
    );
  else return <Spinners />;
};

export default Home;
