import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import noveilAxios from "../configs/axiosConfig";
import Spinners from "./spinners";

const ResetPasswordForm = () => {
  const params = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    delete noveilAxios.defaults.headers["Authorization"];
    noveilAxios
      .post("reset-password/validate_token/", { token: params.token })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setErrorMessage("User not found");
      });
  }, []);

  const redirectToLogin = () => {
    history.push("/login");
  };

  const resetPassword = () => {
    if (password && rePassword && password.length && rePassword.length) {
      if (password !== rePassword) {
        setErrorMessage("Passwords do not match");
        setSuccess(false);
        return;
      }
      delete noveilAxios.defaults.headers["Authorization"];
      noveilAxios
        .post("reset-password/confirm/", {
          token: params.token,
          password: password,
        })
        .then(() => setSuccess(true))
        .catch(() => setSuccess(false));
    } else {
      setErrorMessage("All fields are required");
    }
  };

  if (loading) {
    return <Spinners message={errorMessage} />;
  }
  return (
    <div className={"hero"}>
      <div className={"form-box-password-reset"}>
        {success ? (
          <div className={"input-group"}>
            <h2>Reset successful</h2>
            <button
              type={"submit"}
              onClick={redirectToLogin}
              className={"submit-btn"}
              style={{ marginTop: 10 }}
            >
              Return to Login
            </button>
          </div>
        ) : (
          <div id="login" className={"input-group"}>
            <h2>Enter new password</h2>
            <input
              type={"password"}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              className={"input-field"}
              placeholder={"Password"}
              required
            />
            <input
              type={"password"}
              onChange={(event) => {
                setRePassword(event.target.value);
              }}
              className={"input-field"}
              placeholder={"Re-enter Password"}
              required
            />
            {errorMessage && errorMessage.length ? (
              <p style={{ color: "red" }}>{errorMessage}</p>
            ) : null}
            <button
              type={"submit"}
              onClick={resetPassword}
              className={"submit-btn"}
              style={{ marginTop: 10 }}
            >
              Reset Password
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default ResetPasswordForm;
