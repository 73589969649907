import React, { useEffect, useState } from "react";
import noveilAxios from "../configs/axiosConfig";
import { Redirect } from "react-router-dom";
import Loader from "react-loader-spinner";

const PAGES = { LANDING_PAGE: 0, HOME_PAGE: 1, LOGIN_PAGE: 2 };

const LandingPage = () => {
  const [redirectTo, setRedirectTo] = useState(PAGES.LANDING_PAGE);
  let loginToken = localStorage.getItem("token");
  useEffect(() => {
    noveilAxios
      .get("auth/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + loginToken,
        },
      })
      .then(() => setRedirectTo(PAGES.HOME_PAGE))
      .catch(() => setRedirectTo(PAGES.LOGIN_PAGE));
  }, []);

  switch (redirectTo) {
    case PAGES.HOME_PAGE:
      return <Redirect to={"/home"} />;
    case PAGES.LOGIN_PAGE:
      return <Redirect to={"/login"} />;
    default:
      return (
        <div
          className={"bg"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader type="Hearts" color="#ff105f" height={200} width={200} />
        </div>
      );
  }
};
export default LandingPage;
