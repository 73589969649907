/*

This is the popup modal that appears after a date is finished.
The first modal prompts the user to click whether they liked or disliked the user they dated.
After the user chooses their option, another modal will popup asking the user whether they want to go back to the home page or date again.

Endpoint variables:
    - userLikesDate --> If the user selects Like, then the user will be added to their date's likes' database.
    - userDislikesDate --> If the user selects dislike, then nothing happens

    *Logic should be implemented so that users who have met, never meet again.

*/

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import noveilAxios from "../configs/axiosConfig";

// Constructor for Modal popup after date timer finishes

const Modal = ({ userid, matchName }) => {
  const history = useHistory();
  let connectedUserId = userid;

  // const history = useHistory()

  //This controls the state of when the poll modal popup disappears
  const [poll, setPoll] = useState(true);

  // These control the state of liked or disliked modal appearances based on the user's decision
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  // This is the like button function which makes modal popup disappear and the liked modal appear
  const likeButton = (connectedUserId) => {
    let loginToken = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + loginToken,
      },
      params: {
        user_id: connectedUserId,
      },
    };
    noveilAxios
      .post("like/", { user_id: connectedUserId }, config)
      .then(() => {
        setPoll(false);
        setLiked(true);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("liked");
  };

  // This is the dislike button function which makes modal popup disappear and the disliked modal appear
  const dislikeButton = (connectedUserId) => {
    // let loginToken = localStorage.getItem("token");
    // const config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Token " + loginToken,
    //   },
    //   params: {
    //     user_id: connectedUserId,
    //   },
    // };
    // noveilAxios
    //   .post("dislike/", null, config)
    //   .then(() => {
    //     console.log("success");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    setPoll(false);
    setDisliked(true);
  };

  //This is the endpoint states that store the user's data aon whether or not they liked their date.
  // const [userLikesDate] = useState('');
  // const [userDislikesDate] = useState('');

  // This function is called when the user selects like or dislike, which will post to the database containing their answer
  // const addGenderPreference = () => {

  //     //setUserLikesDate || setUserDislikesDate

  //     .post('http://localhost:3005/users', {
  //         userLikesDate: userLikesDate,
  //         userDislikesDate: userDislikesDate

  //     }).then(()=> {
  //         console.log("success")
  //     }).catch(err => {
  //         console.log(err);
  //     });
  // }
  const startVideoChat = () => {
    window.location.reload(false);
  };

  return (
    <>
      {/*Div holding conditional functions that decide which modal appears (poll vs. liked. vs disliked) */}
      <div>
        {
          //If setpoll is true then this modal, prompting the user to rate their date, will appear
          poll ? (
            //This div formats and styles the div of the modal
            <div className={"modal-bg"}>
              {/*This is the div that styles the modal box*/}
              <div className={"modal-wrapper"}>
                {/*This is the div that styles the text inside the modal*/}
                <div className={"modal-header"}>
                  <h1>{"Your date with " + matchName + " is finished!"}</h1>
                  <h2>Would you like to see them again?</h2>
                </div>

                {/*This is the div that holds the potential answers that a user can choose*/}
                <div className={"answers"}>
                  <i
                    className={"fas fa-thumbs-up fa-3x"}
                    onClick={() => likeButton(connectedUserId)}
                  />
                  <i
                    className={"fas fa-thumbs-down fa-3x"}
                    onClick={() => dislikeButton(connectedUserId)}
                  />
                </div>
              </div>
            </div>
          ) : //If setliked is true then this modal asking the user to keep dating or go home will appear
          liked ? (
            //This div formats and styles the div of the modal
            <div className={"modal-bg"}>
              {/*This is the div that styles the modal box*/}
              <div className={"modal-wrapper"}>
                {/*This is the div that styles the text inside the modal*/}
                <div className={"modal-header"}>
                  <h1>Glad you found someone you liked!</h1>
                </div>

                {/*This is the div that holds the post-poll navigation choices*/}
                <div className={"poll"}>
                  {/*This is the link to continue dating*/}
                  <Link
                    to={"/video-call"}
                    onClick={startVideoChat}
                    className={"submit-link"}
                    style={{ textDecoration: "none" }}
                  >
                    <div className={"poll-a"}>
                      <i className={"fas fa-redo fa-3x"} />
                      <h1>New Date</h1>
                    </div>
                  </Link>

                  {/*This is the link to return home*/}
                  <Link
                    to={"/home"}
                    className={"submit-link"}
                    style={{ textDecoration: "none" }}
                  >
                    <div className={"poll-a"}>
                      <i className={"fas fa-arrow-right fa-3x"} />
                      <h1>Go Home</h1>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : //If setdisliked is true then this modal asking the user to keep dating or go home will appear
          disliked ? (
            //This div formats and styles the div of the modal
            <div className={"modal-bg"}>
              {/*This is the div that styles the modal box*/}
              <div className={"modal-wrapper"}>
                {/*This is the div that styles the text inside the modal*/}
                <div className={"modal-header"}>
                  <h1>Don't worry, you'll find someone soon!</h1>
                </div>

                {/*This is the div that holds the post-poll navigation choices*/}
                <div className={"poll"}>
                  {/*This is the link to continue dating*/}
                  <Link
                    to={"/loading"}
                    className={"submit-link"}
                    style={{ textDecoration: "none" }}
                  >
                    <div className={"poll-a"}>
                      <i className={"fas fa-redo fa-3x"} />
                      <h1>New Date</h1>
                    </div>
                  </Link>

                  {/*This is the link to go home*/}
                  <Link
                    to={"/home"}
                    className={"submit-link"}
                    style={{ textDecoration: "none" }}
                  >
                    <div className={"poll-a"}>
                      <i className={"fas fa-arrow-right fa-3x"} />
                      <h1>Go Home</h1>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            // mandatory else condition that will not be called
            <div />
          )
        }
      </div>
    </>
  );
};

export default Modal;
