/*

This is the report button component which allows a user to report another user.

Endpoint variables:
    - userReported --> This pushes the name of the reported user into a database that will be used to reference all the wrongdoers on the app.

*/

import React, { useState } from "react";
import Popup from "reactjs-popup";
import noveilAxios from "../configs/axiosConfig";

// Report button component for date page screen
const Reportbutton = (props) => {
  //This is the endpoint state that stores the user's inputted gender
  // const [userReported, setUserReported] = useState('');

  // This function is called whenever a user reports another user. This will push that reported user's account to the database and will wait for further decision.
  // const reportUser = () => {
  //     Axios.post('http://localhost:3005/create', {
  //         userReported: userReported,
  //     }).then(()=> {
  //         console.log("success")
  //     }).catch(err => {
  //         console.log(err);
  //     });
  // }

  // This function exectues when a user clicks the report button.
  // const reportButtonClicked = () => {
  //     // The 'userDating' variable would be needed to set the userReported variable so that the data can be pushed to the database
  //     // setUserReported(userDating)
  //     reportUser()
  // }
  const [reported, setReported] = useState(false);
  const reportUser = (e, message) => {
    e.preventDefault();
    noveilAxios
      .post("video-call/report/", { message: message })
      .then(() => {
        setReported(true);
      })
      .catch(() => {
        console.error("Failed to submit report");
      });
  };

  if (reported) {
    return (
      <div className={"report-icon"} style={{ color: "red" }}>
        <p>Reported</p>
      </div>
    );
  }

  return (
    //The report button has not been given an action yet
    <Popup
      trigger={<div onClick={() => { console.log("sammelan") }} className={"report-icon"}>
        <i className="fas fa-flag" />
        <div id={"report-card"}>Report</div>
      </div>}
      contentStyle={{
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        maxHeight: '50%', overflowY: 'scroll', backgroundColor: '#3D160D',
        borderRadius: '10px',
      }}
      position='top right'
      on={['hover', 'focus']}
    >
      {
        (close) => (
          <div style={{ cursor: 'pointer', borderRadius: '10px', }} className="flex flex-col">
            <div style={{ color: 'white', margin: '10px 10px 10px 10px' }} className="padded" onClick={(e) => reportUser(e, "Inappropriate")}>Inappropriate</div>
            <div style={{ color: 'white', margin: '10px 10px 10px 10px' }} className="padded" onClick={(e) => reportUser(e, "Sexual/Nudity")}>Sexual/Nudity</div>
            <div style={{ color: 'white', margin: '10px 10px 10px 10px' }} className="padded" onClick={(e) => reportUser(e, "Hate Speech")}>Hate Speech</div>
          </div>
        )
      }
    </Popup>
    //Div for the report button

  );
};

export default Reportbutton;
