import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk";
import noveilAxios from "./configs/axiosConfig";
import Timer from "./components/dateTimer";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import Loading from "./components/loadingPage";
import Reportbutton from "./components/reportbutton";

let rtc = {
  client: null,
  joined: false,
  published: false,
  localStream: null,
  remoteStreams: [],
  params: {},
};
const POLLING_INTERVAL = 1000;

const VideoCall = (props) => {
  const [prefs, setPrefs] = useState({
    appID: "",
    channel: "",
    uid: "",
    token: "",
    userId: null,
    matchId: null,
    matchName: "",
    iceBreakers: null,
  });
  const [timer, setTimer] = useState(60);
  const [apiHits, setApiHits] = useState(0);
  const [streams, setStreams] = useState(null);
  const [pollingId, setPollingId] = useState(null);
  const [stopTimer, setStopTimer] = useState(false);
  const [authorized, setAuthorized] = useState(true);
  const [locationKeys, setLocationKeys] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [timePolling, setTimePolling] = useState(false);
  const [homeRedirect, setHomeRedirect] = useState(false);
  const [videoTrigger, setVideoTrigger] = useState(false);
  const history = useHistory();

  const redirectToHome = (e) => {
    e.preventDefault();
    history.push("/home");
  };

  const clearAllIntervals = () => {
    const interval_id = window.setInterval(function () { },
      Number.MAX_SAFE_INTEGER);
    for (let i = 1; i < interval_id; ++i) {
      clearInterval(i);
    }
  };

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
          clearAllIntervals();
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
          clearAllIntervals();
        }
      }
    });
  }, [locationKeys]);

  const removeRemoteStream = () => {
    let remoteElement = document.getElementById("remote_video_");
    if (remoteElement) remoteElement.innerHTML = "";
  };

  const removeLocalStream = () => {
    let localElement = document.getElementById("local_stream");
    if (localElement) localElement.innerHTML = "";
  };

  const loading = (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          onClick={() => {
            history.goBack();
          }}
          style={{
            background: "transparent",
            border: "none",
            margin: 30,
            fontSize: 30,
            color: "white",
            cursor: "pointer",
          }}
        >
          <i style={{ backgroundColor: "red" }} class="far fa-times-circle"></i>
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "85vh",
        }}
      >
        <Loading />
        <h1 style={{ color: "white" }}>Finding you a date</h1>
        {errorMessage && errorMessage.length ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <h2 style={{ color: "white" }}>{errorMessage}</h2>
            {homeRedirect ? (
              <button
                onClick={redirectToHome}
                className={"home-start submit-link"}
              >
                Return Home{" "}
              </button>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );

  const getRemainingTime = () => {
    if (typeof pollingId === "number") {
      clearInterval(pollingId);
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    const id = setInterval(() => {
      noveilAxios
        .get("video-call/time/?match_id=" + prefs.matchId, config)
        .then((response) => {
          if (response.status === 200) {
            setTimer(response.data.time_remaining);
          }
        })
        .catch((error) => {
          setErrorMessage(
            "Network problem detected, please reload the page. If error still persists, please log out and log in again"
          );
          if (error.response && error.response.status === 400) {
            setTimer(1);
            setStopTimer(true);
          }
          console.log("ERROR: ", error.response);
        });
    }, POLLING_INTERVAL);
    setPollingId(id);
  };

  const getMatchmakingStatus = () => {
    if (typeof pollingId === "number") {
      clearInterval(pollingId);
    }
    if (apiHits) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.getItem("token"),
        },
      };
      const id = setInterval(() => {
        clearAllIntervals();
        noveilAxios
          .get("video-call/status/", config)
          .then((response) => {
            if (response.status === 200) {
              setPrefs({
                ...prefs,
                appID: response.data.appID,
                token: response.data.token,
                uid: response.data.uid,
                channel: response.data.channel,
                userId: response.data.userId,
                matchId: response.data.match_id,
                matchName: response.data.matchName,
                iceBreakers: response.data.iceBreakers,
              });
              setTimer(response.data.time);
              setAuthorized(true);
              setVideoTrigger(true);
              clearAllIntervals();
              setTimePolling(true);
            } else {
              setApiHits(apiHits + 1);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status)
              if (error.response.status === 404) {
                setErrorMessage("You have no dates remaining");
                return;
              }
            if (error.response === 400) {
              resetVideo();
              return;
            }

            setErrorMessage(
              "Network problem detected, please reload the page. If error still persists, please log out and log in again"
            );
            setHomeRedirect(true);
            setAuthorized(false);
            setApiHits(apiHits + 1);
            if (error.response && error.response.status === 400) {
              setTimer(1);
            }
            console.log("ERROR: ", error.response);
          });
      }, POLLING_INTERVAL);
      setPollingId(id);
    }
  };

  const resetVideo = () => {
    removeRemoteStream();
    removeLocalStream();
    setTimePolling(false);
    clearAllIntervals();
  };

  const startMatchMaking = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    noveilAxios
      .post("video-call/start/", {}, config)
      .then((response) => {
        setApiHits(apiHits + 1);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 404
        ) {
          setErrorMessage("You have no dates remaining");
          setHomeRedirect(true);
          setAuthorized(false);
          return;
        }
        setAuthorized(false);
        console.log("ERROR: ", error.response);
      });
  };

  useEffect(() => {
    if (timePolling) {
      getRemainingTime();
    }
  }, [timePolling]);

  useEffect(() => {
    getMatchmakingStatus();
  }, [apiHits]);

  useEffect(() => {
    setStreams(
      <Grid
        container
        spacing={2}
        alignItems={"flex-start"}
        justifyContent={"center"}
        style={{ height: "95%", width: "95%", position: "relative" }}
      >
        <Grid
          style={{ height: "100%", width: "100%", position: "relative" }}
          container
          xs={12}
          sm={12}
          md={7}
          lg={7}
        >
          <div
            id="remote_video_"
            className="remote_video_"
            style={{
              width: "100%",
              // minHeight: "500px",
              height: "100%",
              marginTop: "10px",
              marginBottom: "10px",
              border: "2px solid white",
              borderRadius: "10px",
              overflow: "hidden",
              position: "absolute",
              display: "flex",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Reportbutton callback={resetVideo} />
          </div>
          <div
            id="local_stream"
            className="local_stream"
            style={{
              top: 10,
              right: 10,
              zIndex: 1,
              width: "20%",
              height: "20%",
              marginTop: "10px",
              position: "absolute",
              marginBottom: "10px",
              border: "2px solid white",
              borderRadius: "10px",
              overflow: "hidden",
              display: "flex",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          ></div>
        </Grid>
        <Grid
          container
          justifyContent="center"
          style={{
            border: '2px solid white', height: '100%', marginTop: '20px', borderRadius: '10px',
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          }}
          item
          xs={12}
          sm={12}
          md={3}
          lg={3}
        >
          <Grid
            container
            justifyContent="center"
            style={{ height: "100%", width: "100%" }}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <div className="right-top">
              <h1>ICE BREAKERS </h1>
              <div className={"icebreakers"}>
                {prefs.iceBreakers && prefs.iceBreakers.length
                  ? prefs.iceBreakers.map((ice, key) => <h2>{ice}</h2>)
                  : null}
              </div>
            </div>
            <div style={{ height: "200px", width: "100px" }}>
              {prefs.userId ? (
                <Timer
                  userId={prefs.userId}
                  matchName={prefs.matchName}
                  timeLimit={timer}
                  callback={() => resetVideo()}
                  rtc={rtc}
                  stopTimer={stopTimer}
                />
              ) : null}
            </div>
          </Grid>
          <Grid
            container
            justifyContent="center"
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >

          </Grid>
        </Grid>
      </Grid>
    );
  }, [timer]);
  useEffect(() => {
    rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "h264" });
    startMatchMaking();
  }, []);
  useEffect(() => {
    if (
      videoTrigger &&
      prefs &&
      prefs.appID &&
      prefs.appID.length &&
      prefs.channel &&
      prefs.channel.length &&
      prefs.uid &&
      prefs.uid.length
    ) {
      rtc.client.init(
        prefs.appID,
        () => {
          console.log("init success");

          // Join a channel
          rtc.client.join(
            prefs.token,
            prefs.channel,
            prefs.uid,
            (uid) => {
              console.log(
                "join channel: " + prefs.channel + " success, uid: " + uid
              );
              rtc.params.uid = uid;
              rtc.client.setClientRole("host");
              // Create a local stream
              rtc.localStream = AgoraRTC.createStream({
                streamID: rtc.params.uid,
                audio: true,
                video: true,
                screen: false,
              });

              // Initialize the local stream
              rtc.localStream.init(
                () => {
                  console.log("init local stream success");
                  rtc.localStream.play("local_stream");
                  rtc.client.publish(rtc.localStream, (err) => {
                    console.log("publish failed");
                    console.error(err);
                  });
                },
                (err) => {
                  console.error("init local stream failed ", err);
                }
              );
              rtc.client.on("connection-state-change", (evt) => {
                console.log("stream-audience", evt);
              });

              rtc.client.on("peer-leave", (evt) => {
                removeRemoteStream();
                console.log("stream-removed");
              });

              rtc.client.on("stream-added", (evt) => {
                let remoteStream = evt.stream;
                let id = remoteStream.getId();
                if (id !== prefs.uid) {
                  rtc.client.subscribe(remoteStream, (err) => {
                    console.log("stream-subscribe failed", err);
                  });
                }
                console.log("stream-added remote-uid: ", id);
              });

              rtc.client.on("stream-removed", (evt) => {
                let remoteStream = evt.stream;
                let id = remoteStream.getId();
                streams.console.log("stream-removed remote-uid: ", id);
              });

              rtc.client.on("stream-subscribed", (evt) => {
                let remoteStream = evt.stream;
                let id = remoteStream.getId();
                remoteStream.play("remote_video_");
                console.log("stream-subscribed remote-uid: ", id);
              });

              rtc.client.on("stream-unsubscribed", (evt) => {
                let remoteStream = evt.stream;
                let id = remoteStream.getId();
                remoteStream.pause("remote_video_");
                console.log("stream-unsubscribed remote-uid: ", id);
              });
              rtc.client.on("error", (evt) => {
                console.log("stream-error");
              });
            },
            (err) => {
              console.error("stream-client join failed", err);
            }
          );
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }, [videoTrigger, prefs.appID, prefs.channel, prefs.uid]);
  return (
    <div className={"dbg"}>
      <div className={"responsive-date-page"}>
        {authorized && videoTrigger ? streams : loading}
      </div>
    </div>
  );
};

export default VideoCall;
