/*

This is the navigation bar component which displays the 'noveil' logo on the far left and the nav buttons on the far right.
This component is displayed on the home, likes, and chat pages.
The user can navigate between all three pages by clicking on their associated logos within the navigaation bar.
There are no endpoints needed for this component.

*/

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import noveilAxios, { updateAuth } from "../configs/axiosConfig";
import logo from "../assets/img/logo.png";

const Navbar = (props) => {
  // const { newLikes, unreadMessages, ...rest } = props;

  const [newLikes, setNewLikes] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState(null);
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    updateAuth();
    noveilAxios
      .get("home/status")
      .then((res) => {
        setNewLikes(res.data.new_likes);
        setUnreadMessages(res.data.unread_messages);
      })
      .catch((err) => console.log(err));

    noveilAxios
      .get("profile/my-profile/")
      .then((res) => {
        // setNewLikes(res.data.new_likes);
        setAvatar(res.data.avatar);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    let config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + localStorage.getItem("token"),
      },
    };
    setInterval(() => {
      noveilAxios
        .get("home/status", config)
        .then((res) => {
          setNewLikes(res.data.new_likes);
          setUnreadMessages(res.data.unread_messages);
        })
        .catch((err) => console.log(err));
    }, 3000);
  }, []);

  return (
    // Navbar div for design
    <div className={"bar"}>
      {/*Logo container design*/}
      <div className={"logo-container"}>
        {/*top left logo for navbar*/}
        <Link to={'/home'} style={{ textDecoration: "none" }}>
          <img src={logo} alt="logo" className="logo-img" />
        </Link>
      </div>

      {/*Likes Icon to go to likes page*/}
      <div className="nav-btns">
        <div className="pd navl">
          <Link
            to={"/likes"}
            className={"submit-link"}
            style={{ textDecoration: "none" }}
          >
            <div className="like-btn">
              <i className="fas fa-heart fa-2x"></i>
              {newLikes ? (
                <span style={notifStyle}>
                  {newLikes >= 10 ? "9+" : newLikes}
                </span>
              ) : null}
            </div>
          </Link>
        </div>

        {/*Chat Icon to go to chat page*/}
        <div className="pd navl">
          <Link to={"/chatPage"} style={{ textDecoration: "none" }}>
            <div className="chat-btn">
              <i className="fa fa-comments fa-2x"></i>
              {unreadMessages ? (
                <span style={notifStyle}>
                  {unreadMessages >= 10 ? "9+" : unreadMessages}
                </span>
              ) : null}
            </div>
          </Link>
        </div>

        <div className="pd navl">
          {/*Home Icon to go to home page*/}
          <Link
            to={"/home"}
            // className={"submit-link"}
            style={{ textDecoration: "none" }}
          >
            <div className="home-btn">
              <i className="fa fa-home fa-2x"></i>
            </div>
          </Link>
        </div>

        {/*Profile  Icon to go to profile page*/}
        <div className="pd navl">
          <Link to={"/profile"}>
            <div className="profile-btn">
              {avatar ? (
                <img
                  style={{
                    height: 30,
                    width: 30,
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                  src={avatar}
                  alt="your-dp"
                />
              ) : (
                <i
                  style={{}}
                  className="fa fa-user fa-2x"
                ></i>
              )}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

const notifStyle = {
  color: "white",
  borderRadius: "100%",
  backgroundColor: "#c9063d",
  position: "absolute",
  fontSize: 14,
  fontWeight: "bold",
  textShadow: "#fff 1px 0 10px",
  height: 21,
  width: 21,
  padding: 2,
  textAlign: 'center'
  // borderColor: "white",
  // borderWidth: 1,
  // borderStyle: "solid",
};
export default Navbar;
