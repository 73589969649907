import React, { useState } from "react";
// This imports the react functions that allows app navigation
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";

import Chat from "./components/chatPage";
import DQuestion from "./components/dateGenderPreference";
import DatePage from "./components/datepage";
import Timer from "./components/dateTimer";
import Modal from "./components/finishedDatePopupModal";
import GQuestion from "./components/genderQuestion";
import Home from "./components/homePage";
import Likes from "./components/likesPage";
import Loading from "./components/loadingPage";
// This imports the different app pages into the master JS file
import Login from "./components/loginPage";
import Personal from "./components/personal";
import ProtectedRoute from "./components/protectedRoute";
import Reportbutton from "./components/reportbutton";
import ResetPassword from "./components/resetPassword";
import University from "./components/university";
import Profilepic from "./components/uploadProfilePic";
import VerifyCode from "./components/verifyCode";
import { LoginContext } from "./helper/context";
import VideoCall from "./videoCall";
import LandingPage from "./components/landingPage";
import Profile from "./components/profile";
import { updateAuth } from "./configs/axiosConfig";
import ChangePassword from "./components/changePassword";
import ResetPasswordForm from "./components/resetPasswordForm";

function App() {
  // This is how the data of the user is accessed throughout the app
  const [username, setUsername] = useState(localStorage.getItem("username"));
  const [isAuthenticated, setIsauthenticated] = useState(false);

  updateAuth();

  return (
    //  This passes the global user data throughout the webapp
    <LoginContext.Provider
      value={{ username, setUsername, isAuthenticated, setIsauthenticated }}
    >
      {/*The Router div allows the navigation of different pages from one another*/}
      <Router>
        <div>
          {/*The Switch div accompanies the Router div*/}
          <Switch>
            <Route path={"/"} exact component={LandingPage} />
            <Route path={"/login"} exact component={Login} />
            <ProtectedRoute path={"/home"} exact component={Home} />
            <ProtectedRoute path={"/gquestion"} exact component={GQuestion} />
            <ProtectedRoute path={"/dquestion"} exact component={DQuestion} />
            <ProtectedRoute path={"/profilepic"} exact component={Profilepic} />
            <ProtectedRoute path={"/likes"} exact component={Likes} />
            {/*<ProtectedRoute path={"/datepage"} exact component={DatePage} />*/}
            <ProtectedRoute path={"/timer"} exact component={Timer} />
            <ProtectedRoute path={"/modal"} exact component={Modal} />
            <ProtectedRoute
              path={"/reportbutton"}
              exact
              component={Reportbutton}
            />
            <ProtectedRoute path={"/chatPage"} exact component={Chat} />
            <ProtectedRoute path={"/loading"} exact component={Loading} />
            <Route path={"/verifycode"} exact component={VerifyCode} />
            <Route path={"/datepage"} exact component={VideoCall} />
            <ProtectedRoute path={"/university"} exact component={University} />
            <Route path={"/resetpassword"} exact component={ResetPassword} />
            <ProtectedRoute path={"/personal"} exact component={Personal} />
            <ProtectedRoute path={"/profile"} exact component={Profile} />
            <ProtectedRoute
              path={"/change-password"}
              exact
              component={ChangePassword}
            />
            <Route
              path={"/reset-password/:token"}
              exact
              component={ResetPasswordForm}
            />
          </Switch>
        </div>
      </Router>
    </LoginContext.Provider>
  );
}

export default App;
