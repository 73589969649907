/*

This is the date preference page where the user can pick what gender user they wish to be paired with.

This page contains mostly styling.

Endpoint variables:
    - genderPreference

When the user clicks one of the options,the data will be saved as the above variable and pushed to the database.

*/

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import noveilAxios from "../configs/axiosConfig";

// This is the date question page on the register questionnaire

const DQuestion = () => {
  // Variable holding the question
  const q2 = "Who are you looking to date?";

  // Button to go back to previous page
  let history = useHistory();

  //This is the endpoint state that stores the user's inputted gender preference
  const [genderPreference, setGenderPreference] = useState("");

  // This function is called when the user selects their gender preference, which will post to the database containing their answer
  const addGenderPreference = (value) => {
    let loginToken = localStorage.getItem("token");

    const formData = new FormData();

    formData.append("interest", value);

    noveilAxios
      .post("personal/interest/", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + loginToken,
        },
      })
      .then(() => {
        console.log("success");
        history.push("/university");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    //This is the background styling
    <div className={"bg"}>
      {/*This is the icon to go back to previous page*/}
      <i className="fas fa-arrow-left" onClick={history.goBack} />

      {/*This is the dib holding the question*/}
      <div className={"question"}>
        <h3>{q2}</h3>
      </div>

      {/*This is the div holding the answers*/}
      <div className={"answers"}>
        {/*Div to vertically align cards*/}
        <div
          className={"vert-card"}
          id={"dateMen"}
          onClick={() => addGenderPreference(1)}
        >
          {/*Div to center answer in card*/}
          <div className={"vert-center"}>
            <h1>
              <i className="fa fa-male" />
            </h1>
            <h2>MAN</h2>
          </div>
        </div>

        {/*Div to vertically align cards*/}
        <div
          className={"vert-card"}
          id={"dateWomen"}
          onClick={() => addGenderPreference(2)}
        >
          {/*Div to center answer in card*/}
          <div className={"vert-center"}>
            <h1>
              <i className="fa fa-female" />
            </h1>
            <h2>WOMAN</h2>
          </div>
        </div>

        {/*Div to vertically align cards*/}
        <div
          className={"vert-card"}
          id={"dateEveryone"}
          onClick={() => addGenderPreference(3)}
        >
          {/*Div to center answer in card*/}
          <div className={"vert-center"}>
            <h1>
              <i className="fas fa-heart" />
            </h1>
            <h2>EVERYONE</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DQuestion;
