/*

This is the login page where the user can either log in to an existing account or register a new one.
The main view is a box that toggles between the login view and register view, by pressing the button on top.

Some backend logic has been implemented for testing purposes.
    - When the user registers, the data gets saved in the backend database.
    - The user can then log in with that same phone number used to register.

Endpoint variables:

    *The endpoint variables below are structured for phone login / registration and does not include a robust verification system.
    *The Google / Facebook API's will most likely have their own verification setup and will require tweaks to the endpoints below

    - loggedIn --> This endpoint executes on the login side checks whether the number a user tries to log in with is already stored in the backend.
    - numStatus --> This endpoint executes on the registration side and checks whether the number a user enters is new or not.
    - addUser --> This endpoint executes on the registration side and pushes the successfully registered user into the users database.



*/

import noveilAxios, { updateAuth } from "../configs/axiosConfig";
import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { LoginContext } from "../helper/context";

// Constructor for login page

// This registration format is constructed for phone number -- we have yet to see how this would adjust with social media

const PAGES = { HOME_PAGE: 0, LOGIN_PAGE: 1 };

function Login() {
  let history = useHistory();

  // Function that manages the conditional view state between the login and register page
  const [show, setShow] = useState(true);
  const [redirectTo, setRedirectTo] = useState(PAGES.LOGIN_PAGE);
  const [errorMessage, setErrorMessage] = useState(null);

  //This state shows whether or not the user is logged in
  const [loginStatus, setLoginStatus] = useState("");

  // This state basically checks whether the number used to register is a new number

  // This state is the catalyst for knowing who the user is throughout the entire app
  // const {userData, setUserData} = useContext(LoginContext)

  // When the user clicks the login toggle, it will go to the login page
  const logswitch = () => {
    setShow(true);
    setErrorMessage(null);
  };

  // When the user clicks the registration toggle, it will go to the registration page
  const regswitch = () => {
    setShow(false);
    setErrorMessage(null);
  };

  //These states are for the registration information of the user
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [signupClicked, setSignupClicked] = useState(false);

  const [agreed, setAgreed] = useState(false);
  const { setUsername, setIsauthenticated } = useContext(LoginContext);

  // When this function executes, the newly registered user's information will be added into the database
  const addUser = (e) => {
    e.preventDefault();
    setSignupClicked(true);
    if (!agreed) return;

    if (password1 !== password2) return;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    delete noveilAxios.defaults.headers["Authorization"];
    const formData = new FormData();

    formData.append("email", email);
    formData.append("password1", password1);
    formData.append("password2", password2);

    noveilAxios
      .post("signup/", formData, config)
      .then((res) => {
        console.log("success");
        setIsauthenticated(true);
        localStorage.setItem("token", res.data.key);
        history.push("/verifycode");
      })
      .catch((error) => {
        if (error?.response?.data) {
          let errMsg = "";
          for (let key in error.response.data) {
            errMsg += error.response.data[key] + "\n";
          }
          setErrorMessage(errMsg);
        } else {
          setErrorMessage(error.message);
        }
      });
  };

  //When this function executes, the user's number will be checked, if its valid then the user can log in.
  const loggedIn = () => {
    const formData = new FormData();

    formData.append("email_or_username", email);
    formData.append("password", password);
    formData.append("password2", password);
    delete noveilAxios.defaults.headers["Authorization"];

    noveilAxios
      .post("login/", formData)
      .then((res) => {
        // console.log("from res", res.statusText);
        if (res.data.message) {
          setLoginStatus(res.data.message);
          setIsauthenticated(true);
        } else {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("username", res.data.user.username);
          localStorage.setItem("email", res.data.user.email);
          setUsername(res.data.user.username);
          updateAuth();
          history.push("/home");
        }
      })
      .catch((error) => {
        if (error?.response?.data) {
          let errMsg = "";
          for (let key in error.response.data) {
            errMsg += error.response.data[key] + "\n";
          }
          setErrorMessage(errMsg);
        } else {
          setErrorMessage(error.message);
        }
      });
  };

  // This function detects when the registration form is submitted, which will then send them to the remaining questions
  // const formSubmitted = () => {
  //     document.addEventListener('submit', () => {
  //         if (show) {
  //         } else
  //         {

  //             history.push("/gquestion")
  //         }
  //     })
  // }

  // This function allows the user to submit the form which will execute the addUser function and the formSubmitted function
  // const submitForm = () => {
  //     addUser();
  //     formSubmitted()
  // }

  useEffect(() => {
    let loginToken = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + loginToken,
      },
    };
    noveilAxios
      .get("auth/", config)
      .then(() => setRedirectTo(PAGES.HOME_PAGE))
      .catch(() => setRedirectTo(PAGES.LOGIN_PAGE));
  }, []);

  // console.log(agreed)
  // console.log("pw match", password1.match("[ ]{1,}"));
  switch (redirectTo) {
    case PAGES.HOME_PAGE:
      return <Redirect to={"/home"} />;
    default:
      return (
        // Div holding conditional functions that decide between the login or register page
        <div>
          {
            //If show is true then the login page will appear
            show ? (
              // Div that sets the background with the image and color settings
              <div className={"hero"}>
                {/* Div that sets the styling for the form box */}
                <div className={"form-box"}>
                  {/*Div that sets the styling for the toggle button container*/}
                  <div
                    className={"button-box"}
                    // onClick={() => setErrorMessage(null)}
                  >
                    {/*Div that sets the styling for the toggle buttons */}
                    <div className={"btn"}>
                      <button type={"button"} className={"toggle-right"}>
                        Login
                      </button>
                      <button
                        type={"button"}
                        className={"toggle-btn"}
                        onClick={regswitch}
                      >
                        Register
                      </button>
                    </div>
                  </div>

                  {/*Login form questions*/}
                  <div id="login" className={"input-group"}>
                    <input
                      type={"email"}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      className={"input-field"}
                      placeholder={"Email"}
                      required
                    />
                    <input
                      type={"password"}
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                      className={"input-field"}
                      placeholder={"Password"}
                      required
                    />

                    {/* Button link to submit form and log in */}
                    {/*<Link to={"/home"} className={"submit-link"} style={{ textDecoration: 'none' }}>*/}
                    {errorMessage && (
                      <p style={{ color: "red", fontSize: 12 }}>
                        {errorMessage}
                      </p>
                    )}

                    <button
                      type={"submit"}
                      style={{ "margin-top": "15px" }}
                      onClick={loggedIn}
                      className={"submit-btn"}
                    >
                      Login
                    </button>

                    <div className={"logmessage"}>{loginStatus}</div>

                    <div>
                      <Link
                        to="/resetpassword"
                        style={{
                          textDecoration: "none",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 10,
                        }}
                      >
                        <span>Forgot Password</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // If show is not true then the register page will appear
              // Div that sets the background with the image and color settings
              <div className={"hero"}>
                {/* Div that sets the styling for the form box */}
                <div className={"form-box2"}>
                  {/*Div that sets the styling for the toggle button container*/}
                  <div className={"button-box"}>
                    {/*Div that sets the styling for the toggle buttons */}
                    <div className={"btn"}>
                      <button
                        type={"button"}
                        className={"toggle-btn"}
                        onClick={logswitch}
                      >
                        Login
                      </button>
                      <button type={"button"} className={"toggle-right"}>
                        Register
                      </button>
                    </div>
                  </div>
                  <button type={"button"} className={"instagram-btn"}>
                    Register with Instagram
                  </button>

                  {/*Registration form questions*/}
                  <form id="register" className={"input-group"}>
                    <input
                      type={"email"}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      className={"input-field"}
                      placeholder={"Email"}
                      required
                    />
                    <input
                      type={"password"}
                      onChange={(event) => {
                        setPassword1(event.target.value);
                      }}
                      className={"input-field"}
                      placeholder={"Password"}
                      required
                    />
                    <input
                      type={"password"}
                      onChange={(event) => {
                        setPassword2(event.target.value);
                      }}
                      className={"input-field"}
                      placeholder={"Confirm Password"}
                      required
                    />
                    {errorMessage && (
                      <p style={{ color: "red", fontSize: 12 }}>
                        {errorMessage}
                      </p>
                    )}

                    {password1 !== password2 && (
                      <p style={{ color: "red", fontSize: 12 }}>
                        Both Passwords are not Same.
                      </p>
                    )}
                    <input
                      type={"checkbox"}
                      className={"check-box"}
                      onClick={() => setAgreed(!agreed)}
                    />

                    <span style={{ marginLeft: 10 }}>
                      {" "}
                      I agree to the terms & conditions.
                    </span>

                    {signupClicked && !agreed && (
                      <p style={{ color: "red", fontSize: 12 }}>
                        Please check this field..
                      </p>
                    )}

                    {/* Button link to submit form and register */}
                    <button
                      type={"submit"}
                      onClick={addUser}
                      className={"submit-btn"}
                      style={{ marginTop: 20 }}
                    >
                      Register
                    </button>
                  </form>
                </div>
              </div>
            )
          }
        </div>
      );
  }
}

export default Login;
