import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "./navigationBar_homepage_likespage";
import { Grid } from "@mui/material";
import noveilAxios, { updateAuth } from "../configs/axiosConfig";

const ChangePassword = () => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [success, setSuccess] = useState(false);

  const redirectToHome = (e) => {
    e.preventDefault();
    history.push("/home");
  };

  //When this function executes, the old password gets replaced with new.
  const changePassword = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("old_password", oldPassword);
    formData.append("new_password", newPassword);
    formData.append("confirm_password", confirmPassword);

    updateAuth();
    noveilAxios
      .post("profile/change-password/", formData)
      .then((res) => {
        setSuccess(true);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          setErrorMessage("Network error");
          return;
        }
        if (error?.response?.data) {
          let errMsg = "";
          for (let key in error.response.data) {
            errMsg += error.response.data[key] + "\n";
          }
          setErrorMessage(errMsg);
        } else {
          setErrorMessage(error.message);
        }
      });
  };
  return (
    <div className="bg">
      <Navbar />

      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          xs={12}
          sm={8}
          md={6}
          lg={4}
          xl={3}
          style={{
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: 20,
          }}
        >
          <form
            className={"input-group"}
            style={{
              backgroundColor: "rgba(0, 0, 0, .5)",
              padding: 10,
              borderRadius: 10,
            }}
          >
            <h3 style={{ textAlign: "center", color: "white" }}>
              Change Password
            </h3>
            <input
              type={"password"}
              onChange={(event) => {
                setOldPassword(event.target.value);
              }}
              className={"input-field"}
              placeholder={"Old Password"}
              required
            />
            <input
              type={"password"}
              onChange={(event) => {
                setNewPassword(event.target.value);
              }}
              className={"input-field"}
              placeholder={"New Password"}
              required
            />
            <input
              type={"password"}
              onChange={(event) => {
                setConfirmPassword(event.target.value);
              }}
              className={"input-field"}
              placeholder={"Confirm Password"}
              required
            />
            {success ? (
              <div style={{ textAlign: "center" }}>
                <p style={{ color: "white", fontSize: 12 }}>
                  {"Password changed successfully"}
                </p>
                <button
                  onClick={redirectToHome}
                  className={"submit-btn"}
                  style={{ marginTop: 20 }}
                >
                  Return Home
                </button>
              </div>
            ) : null}
            {errorMessage && (
              <p style={{ color: "red", fontSize: 12 }}>{errorMessage}</p>
            )}

            {/* Button link to submit form and change password */}
            {!success && !errorMessage && <button
              type={"submit"}
              onClick={changePassword}
              className={"submit-btn"}
              style={{ marginTop: 20 }}
            >
              Update Password
            </button>}
          </form>
        </Grid>
      </div>
    </div>
  );
};

export default ChangePassword;
