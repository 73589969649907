import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "./navigationBar_homepage_likespage";
import { Grid, Button } from "@mui/material";
import noveilAxios, { updateAuth } from "../configs/axiosConfig";
import Spinners from "./spinners";
import avatar from "../assets/img/avatar.jpg";

const Profile = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    updateAuth();
    noveilAxios
      .get("profile/my-profile/")
      .then((res) => {
        let {
          first_name,
          avatar,
          username,
          interest,
          gender,
          last_name,
          education,
        } = res.data;

        setUserInfo({
          first_name,
          avatar,
          username,
          interest,
          gender,
          last_name,
          education,
        });
        setLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          setErrorMessage("Network error");
          return;
        }
        if (error?.response?.data) {
          let errMsg = "";
          for (let key in error.response.data) {
            errMsg += error.response.data[key] + "\n";
          }
          setErrorMessage(errMsg);
        } else {
          setErrorMessage(error.message);
        }
      });
  }, []);

  //   const [isEditing, setIsEditing] = useState(false);

  if (loading) {
    return <Spinners message={errorMessage} />;
  }

  return (
    <div className="bg">
      <Navbar />
      <div className="home-card">
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          style={{ textAlign: "center", fontSize: 20, position: "relative" }}
        >
          <Grid
            item
            xs={12}
            sm={10}
            md={9}
            lg={5}
            xl={4}
            container
            alignItems={"center"}
            justifyContent={"center"}
          >
            <div className={"home-card"}>
              <Grid
                container
                style={{
                  textAlign: "center",
                  padding: "20px",
                  position: "relative",
                }}
              >
                <Grid style={{ textAlign: "left" }} item xs={12} md={4}>
                  <img
                    style={{
                      width: 150,
                      height: 150,
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                    src={
                      userInfo.avatar.length === 0 ? avatar : userInfo.avatar
                    }
                    alt="your-dp"
                  />
                </Grid>

                <Grid
                  item
                  style={{ marginLeft: 20, textAlign: "left", color: "white" }}
                  item
                  xs={12}
                  md={6}
                >
                  <p>@{userInfo.username}</p>
                  <p>{`${userInfo.first_name} ${userInfo.last_name} (${userInfo.gender})`}</p>
                  <p>Interested in {userInfo.interest}</p>
                  <p>Student at {userInfo.education}</p>
                  <div style={{ marginTop: 20, width: "100%" }}>
                    <button
                      style={{ display: "initial" }}
                      className="submit-btn"
                      onClick={() => history.push("/change-password")}
                    >
                      Change Password
                    </button>
                  </div>
                </Grid>

                <div
                  className={"online"}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    width: "100%",
                  }}
                ></div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Profile;
