import { useState } from "react";
import { useHistory } from "react-router-dom";
import noveilAxios, { updateAuth } from "../configs/axiosConfig";

function University() {
  let history = useHistory();

  const [university, setUniversity] = useState("");

  const addUniversity = () => {
    let logintoken = localStorage.getItem("token");

    const formData = new FormData();

    formData.append("university", university);

    noveilAxios
      .post("education/", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + logintoken,
        },
      })
      .then(() => {
        console.log("success");
        updateAuth();
        history.push("/home");
      })
      .catch((err) => {
        localStorage.removeItem("token");
        console.log(err);
      });
  };

  return (
    <div className={"bg"}>
      <div style={{ height: "200px" }} className={"form-box"}>
        <h4 style={{ "text-align": "center" }}> Enter your university </h4>
        <input
          style={{ "margin-top": "50px", padding: "10px" }}
          type={"text"}
          onChange={(event) => {
            setUniversity(event.target.value);
          }}
          className={"input-field"}
          placeholder={"University Name"}
          required
        />
        <button
          style={{ "margin-top": "15px" }}
          onClick={addUniversity}
          className="submit-btn"
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default University;
