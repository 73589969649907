/*

This is the home page icon component where the user will have access to the 'dates left' data, 'date timer reset' data, and the sign out button.
The user's information on their dates will be on the left, and the signout button will be on the right.

Endpoint variables:
    - datesLeft --> This endpoint stores the data of the amount of users currently online.
    - dateTimerReset --> This endpoint stores the exact time of when the user ran out of dates.


*/

import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";
import noveilAxios, { updateAuth } from "../configs/axiosConfig";

// Icon constructors for dates left, time reset, and signout components

const Icons = (props) => {
  const { resetTime, datesRemaining, dated } = props;

  const history = useHistory();

  const secondsToHms = (d) => {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);
    if (h < 10) h = String("0" + String(h));
    else h = String(h);

    if (m < 10) m = String("0" + String(m));
    else m = String(m);

    if (s < 10) s = String("0" + String(s));
    else s = String(s);
    return [h, m, s];
  };

  const [hours, minutes, seconds] = secondsToHms(resetTime);

  const logout = () => {
    updateAuth();
    noveilAxios
      .post("logout/", {})
      .then(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        history.push("/login");
      })
      .catch((error) => console.error("ERROR", error));
    delete noveilAxios.defaults.headers["Authorization"];
  };

  return (
    //Flex style div to display both icons at the left and right side of screen
    <Grid
      container
      justifyContent={"space-around"}
      alignItems={"center"}
      style={{ bottom: "4%" }}
    >
      {/* card div for dates-left*/}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        style={{
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          padding: "20px",
        }}
      >
        {/*Text div for dates left*/}
        <div id={"datesleft-card"} className={"content"}>
          {/*BELOW WILL BE REPLACED WITH */}
          <h2>You have {datesRemaining} dates left.</h2>

          {/*BELOW WILL BE REPLACED WITH <p></p>*/}
          <p>
            Your dates will reset in{" "}
            <span id={"date-reset"}>
              {dated ? hours + ":" + minutes + ":" + seconds + "" : "24:00:00"}
            </span>
          </p>
        </div>
      </Grid>

      {/* card div for signout-card */}
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        style={{
          justifyContent: "center",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <div onClick={logout} className={"icon-card"}>
          {/*button design for signout-card*/}
          <div
            onClick={() => logout}
            id={"signout-card"}
            style={{ textDecoration: "none" }}
          >
            SIGN OUT
          </div>

          {/*icon for sign-out */}
          {/*<i className="fas fa-sign-out-alt fa-3x"/>*/}
        </div>
      </Grid>
    </Grid>
  );
};

export default Icons;
