/*

This is the gender question page where the user can select which gender they identify with.

This page contains mostly styling.

Endpoint variables:
    - addUserGender --> This endpoint stores the gender of the user.

When the user clicks one of the options,the data will be saved as the above variable and pushed to the database.

*/

import React from "react";
import { useHistory } from "react-router-dom";
import noveilAxios from "../configs/axiosConfig";

// Gender question in new account registration

const GQuestion = () => {
  // Variable holding question
  const q1 = "Select your gender";

  let history = useHistory();

  // This function is called when the user selects a gender, which will post to the database containing their answer
  const addUserGender = (value) => {
    let logintoken = localStorage.getItem("token");

    const formData = new FormData();

    formData.append("gender", value);

    noveilAxios
      .post("personal/gender/", formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + logintoken,
        },
      })
      .then(() => {
        console.log("success");
        history.push("/dquestion");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    //This is the background styling
    <div className={"bg"}>
      {/*Go back icon*/}
      <i
        className="fas fa-arrow-left"
        onClick={history.goBack}
      ></i>

      {/*Question Div*/}
      <div className={"question"}>
        <h3>{q1}</h3>
      </div>

      {/*Answers Div*/}
      <div className={"answers"}>
        {/*Div to vertically align cards*/}
        <div className={"vert-card"} onClick={() => addUserGender(1)}>
          {/*Div to center answer in card*/}
          <div className={"vert-center"}>
            <h1>
              <i className="fa fa-male"></i>
            </h1>
            <h2>MAN</h2>
          </div>
        </div>

        {/*Div to vertically align cards*/}
        <div className={"vert-card"} onClick={() => addUserGender(2)}>
          {/*Div to center answer in card*/}
          <div className={"vert-center"}>
            <h1>
              <i className="fa fa-female"></i>
            </h1>
            <h2>WOMAN</h2>
          </div>
        </div>

        {/*Div to vertically align cards*/}
        <div className={"vert-card"} onClick={() => addUserGender(3)}>
          {/*Div to center answer in card*/}
          <div className={"vert-center"}>
            <h1>
              <i className="fas fa-plus"></i>
            </h1>
            <h2>MORE</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GQuestion;
